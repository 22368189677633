import { Injectable } from '@angular/core'

export type CacheData = {
  data: any
  timestamp: number
}

@Injectable({
  providedIn: 'root',
})
export class CacheStorageService {
  private cache: Map<string, CacheData> = new Map<string, CacheData>()

  set(key: string, data: any): void {
    const timestamp: number = Date.now()
    this.cache.set(key, { data, timestamp })
  }

  get(key: string): any | null {
    const cachedItem = this.cache.get(key)
    if (cachedItem) {
      const currentTime: number = Date.now()
      const age: number = currentTime - cachedItem.timestamp

      if (age < 12 * 60 * 60 * 1000) {
        return cachedItem.data
      } else {
        this.cache.delete(key)
      }
    }

    return null
  }

  clear(key: string): void {
    this.cache.delete(key)
  }

  clearAll(): void {
    this.cache.clear()
  }
}
