import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { DataService } from '@core/interfaces/data-service'
import { Dropdown, DropdownChangeEvent, DropdownModule } from 'primeng/dropdown'
import { Entity } from '@core/models/entity'
import { NgClass, NgIf } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { ControlLabelComponent } from '@app/components/uiux/control-label/control-label.component'
import { Subscription } from 'rxjs'
import { InputGroupModule } from 'primeng/inputgroup'
import { ButtonComponent } from '@app/components/uiux/button/button.component'
import { TooltipModule } from 'primeng/tooltip'
import { FilterMetadata } from 'primeng/api/filtermetadata'
import { CacheStorageService } from '@core/services/cache-storage.service'
import { ApiService } from '@core/services/api/api.service'

@Component({
  standalone: true,
  selector: 'tb-entity-select',
  templateUrl: 'entity-select.component.html',
  imports: [
    DropdownModule,
    NgClass,
    FormsModule,
    ControlLabelComponent,
    NgIf,
    InputGroupModule,
    ButtonComponent,
    TooltipModule,
  ],
})
export class EntitySelectComponent implements OnInit, OnDestroy {

  @ViewChild('dropdown') dropdown!: Dropdown

  @Input({ required: true }) dataService!: DataService
  @Input({ required: true }) optionLabel!: ((item: any) => string) | string | undefined
  @Input({ required: true }) label: string = ''
  @Input({ required: true }) invalid: boolean = false
  @Input() required: boolean = false
  @Input() disabled: boolean = false
  @Input() hideErrorMessages: boolean = false
  @Input() style: object = {}
  @Input() value!: object | null
  @Input() openUrl: string = ''
  @Input() additionalFilters: { [s: string]: FilterMetadata } | null = null
  @Input() appendTo: string = 'body'

  @Output() onSelected: EventEmitter<any> = new EventEmitter<any>()

  // internal properties
  protected id: string
  protected options: Entity[] = []
  protected selectedOption: Entity | null = null
  protected loaded: boolean = false
  protected loading: boolean = false

  private dataSubscription!: Subscription

  constructor(private cd: ChangeDetectorRef, private cacheStorageService: CacheStorageService) {
    this.id = Math.random().toString(36).substring(2)
  }

  ngOnInit(): void {
    // when there is a default value, then show this value by setting the option manually
    if (this.value) {
      this.setInitialValue()
    }
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe()
    }
  }

  private loadFromCache(): boolean {
    const cacheKey = this.createCacheKey()
    const cachedData = this.cacheStorageService.get(cacheKey)

    if (cachedData) {
      this.options = cachedData
      this.loaded = true
      this.cd.markForCheck()
      return true
    }

    this.loaded = false
    return false
  }

  private load(): void {
    if (this.loadFromCache()) return

    if (this.dataService.getCollectionSelect?.('')) {
      this.loading = true
      this.options = []

      this.dataSubscription = this.dataService.getCollectionSelect('', this.additionalFilters ? { filters: this.additionalFilters } : null)
        .subscribe(entity => {
          this.options = entity
          this.storeCacheData()
          this.loading = false
          this.loaded = true
          this.cd.markForCheck()
        })
    } else {
      console.error('Please implement getCollectionSelect for DataService: ', this.dataService)
    }
  }

  private createCacheKey(): string {
    const filters: string = this.additionalFilters ? JSON.stringify(this.additionalFilters) : ''

    const apiService: ApiService = this.dataService as unknown as ApiService

    if (typeof apiService.getEndpoint === 'function') {
      const endpoint: string = apiService.getEndpoint()
      return `${ endpoint }-select-${ filters }`
    } else {
      return `select-${ filters }`
    }
  }

  private storeCacheData(): void {
    const cacheKey: string = this.createCacheKey()
    this.cacheStorageService.set(cacheKey, this.options)
  }

  protected onClear(): void {
    this.selectedOption = null

    if (this.dropdown.editableInputViewChild) {
      this.dropdown.editableInputViewChild.nativeElement.value = ''
    }
  }

  protected onChanged(event: DropdownChangeEvent): void {
    this.onSelected.emit(event.value)
  }

  protected onLazyLoad(): void {
    if (!this.loaded) {
      this.load()
    }
  }

  protected getOptionLabel(item: any): string {
    if (typeof this.optionLabel === 'string') {
      return item[ this.optionLabel ] ?? '-'
    }

    if (typeof this.optionLabel === 'function') {
      return this.optionLabel(item)
    }

    return '-'
  }

  private setInitialValue(): void {
    this.selectedOption = this.value as Entity
    this.options.push(this.selectedOption)
    this.cd.detectChanges()
  }

  public reload() {
    this.loaded = false
  }

  protected onOpen(): void {
    if (this.selectedOption && this.openUrl.length > 0) {
      const win = window.open(location.origin + this.openUrl + this.selectedOption.id, '_blank')
      if (win) {
        win.focus()
      }
    }
  }

}
