import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core'
import { EnumService } from '@core/services/api/enum.service'
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown'
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms'
import { EnumCase } from '@core/interfaces/enum-case'
import { NgClass, NgIf } from '@angular/common'
import { BaseFormControlComponent } from '@app/components/base/base-form-control.component'
import { ControlLabelComponent } from '@app/components/uiux/control-label/control-label.component'

@Component({
  selector: 'tb-enum-select',
  standalone: true,
  templateUrl: './enum-select.component.html',
  imports: [
    DropdownModule,
    FormsModule,
    NgClass,
    NgIf,
    ControlLabelComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnumSelectComponent),
      multi: true
    },
  ],
})
export class EnumSelectComponent extends BaseFormControlComponent {

  @Input({ required: true }) enumName: string = ''
  @Input() showClear: boolean = false
  @Input() style: object = {}
  @Input() defaultValue: number | null = null

  @Output() onValueChange: EventEmitter<any> = new EventEmitter<any>()

  protected enums: EnumCase[] = []

  constructor(
    protected enumService: EnumService,
  ) {
    super(...BaseFormControlComponent.deps())
  }

  override init(): void {
    const enums = this.enumService.getOptions(this.enumName)

    if (enums !== undefined) {
      this.enums = enums.cases
    }
  }

  onClear(): void {
    this.value = this.defaultValue
  }

  // emit event
  onValueChanged(event: DropdownChangeEvent): void {
    this.onValueChange.emit(event)
    this.onChange(this.value)
  }

}
