<div class="input-group">
	<tb-control-label [id]="id" [label]="label" [required]="required" [invalid]="invalid" />

	<p-inputGroup>
		<p-dropdown
			#dropdown
			dataKey="id"
			[appendTo]="appendTo"
			[id]="id"
			[(ngModel)]="selectedOption"
			[options]="options"
			[showClear]="true"
			[disabled]="disabled"
			[required]="required"
			[style]="style"
			[class]="invalid ? 'ng-dirty ng-invalid w-full' : 'w-full'"
			(onClear)="onClear()"
			(onChange)="onChanged($event)"
			(onShow)="onLazyLoad()"
		>
			<ng-template pTemplate="empty">
				<ng-container *ngIf="loading else elseIf">Lädt...</ng-container>
				<ng-template #elseIf>Keine Einträge vorhanden</ng-template>
			</ng-template>

			<ng-template pTemplate="selectedItem">
				<div class="item">
					<div>{{ getOptionLabel(selectedOption) }}</div>
				</div>
			</ng-template>

			<ng-template let-item pTemplate="item">
				<div class="item">
					<div>{{ getOptionLabel(item) }}</div>
				</div>
			</ng-template>
		</p-dropdown>

		<tb-btn
			*ngIf="selectedOption && openUrl.length > 0"
			[secondary]="true"
			(onClick)="onOpen()"
			label=""
			icon="pi pi-external-link"
			pTooltip="Öffnen"
		/>
	</p-inputGroup>

	<div *ngIf="!hideErrorMessages" class="input-error-message">
		<span *ngIf="invalid">Pflichtfeld</span>
	</div>
</div>
