<div class="input-group">
	<tb-control-label [id]="id" [label]="label" [required]="required" [invalid]="invalid" [tooltip]="tooltip" />

	<p-dropdown
		[appendTo]="appendTo"
    [(ngModel)]="value"
    [options]="enums"
    [showClear]="showClear"
    [required]="required"
    [disabled]="disabled"
    (onChange)="onValueChanged($event)"
    (onClear)="onClear()"
    [style]="style"
		class="w-full"
  />

	<div *ngIf="!hideErrorMessages" class="input-error-message">
		<ng-container *ngIf="invalid">
			<span *ngIf="control.errors?.['required']">Pflichtfeld</span>
		</ng-container>
  </div>
</div>
